:root {
  --aa-primary-color-rgb: 66, 66, 66;
  --aa-panel-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  --aa-panel-max-height: 400px;
  --aa-selected-color-rgb: 0, 0, 0;
  --aa-selected-color-alpha: 0.05;
}

.aa-Panel {
  z-index: 2000;
  position: fixed;
  top: 54px !important;
}

.aa-Form {
  border: none;
}

.aa-DetachedSearchButton {
  background-color: transparent;
  border: none;
}
.aa-DetachedSearchButtonIcon {
  color: white;
}
.aa-DetachedSearchButton:focus {
  box-shadow: none;
}
.aa-DetachedSearchButtonPlaceholder {
  display: none;
}

@media only screen and (max-width: 960px) {
  .aa-Panel {
    top: 0px !important;
  }
}
